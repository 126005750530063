window._ = require('lodash');
const Swal = require('sweetalert2')

window.Vue = require('vue').default;

require('../assets/vendor/MediaManager/js/manager')

try {
    $ = window.$ = window.jQuery = require('jquery');
    require('bootstrap');


    new Vue({
        el: '#app'
    })

    //Delete sub element
    $(document).on('click', '.removeData', function () {
        var id = $(this).data('id');
        var section = $(this).data('section');
        var conteiner = $(this).data('conteiner');
        // Elimnar
        Swal.fire({
            icon: 'warning',
            text: 'Esta seguro de eliminar este item?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText:'Cancelar',
            confirmButtonColor: '#198754',
            cancelButtonColor: '#dc3545',
        }).then((result) => {
            if (result.isConfirmed) {

                if(section != 'section_description')
                    var html = `<input type="hidden" name="${section}[image_edit][id][]" value="${id}">`;
                else
                    var html = `<input type="hidden" name="${section}[description_edit][id][]" value="${id}">`;

                $(conteiner).append(html);
                $(this).closest('.add_row').remove();
            }
        });
    });


    // Delete element
    function deleteConfirm(formId) {
        Swal.fire({
            icon: 'warning',
            text: 'Esta seguro de eliminar este item?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText:'Cancelar',
            confirmButtonColor: '#198754',
            cancelButtonColor: '#dc3545',
        }).then((result) => {
            if (result.isConfirmed) {
                $('#' + formId).submit();
            }
        });
    }

    $(document).on('click', '.delete',function(event) {
        event.preventDefault();
        let formid = $(this).data("formid");
        deleteConfirm(formid);
    });

    // Create or Update
    $(function() {

      $('[data-toggle="tooltip"]').tooltip();

      $(document).on("change", ".image-file", function() {
        var picture = $(this).data('input');
        var file = this.files[0];
        if(file.type != "image/png" && file.type != "image/jpeg" && file.type != "image/gif")
        {
            alert("Please choose png, jpeg or gif files");
            return false;
        }
        var reader = new FileReader();
        reader.onload = function(e) {
            $("#" + picture).children('img').attr('src', e.target.result);
        }
        reader.readAsDataURL(file);
        $("#" + picture).show();
      });

      $('.needs-validation').on("submit", function(event) {
        console.log("submit");
        if (!this.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          $(this).addClass('was-validated');
      })

    })

} catch (e) {}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

