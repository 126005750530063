var render, staticRenderFns
import script from "./manager.vue?vue&type=script&lang=js"
export * from "./manager.vue?vue&type=script&lang=js"
import style0 from "../../sass/packages/bulma.scss?vue&type=style&index=0&id=776a93f0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "776a93f0",
  null
  
)

export default component.exports